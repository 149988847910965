<template>
	<!-- 头部表单 -->
	<div>
		<el-form
			ref="queryForm"
			class="query-box"
			v-model="queryParams"
			:inline="true"
		>
			<el-form-item
				label="代理商/机构编号"
				prop="name"
				label-width="120px"
			>
				<el-input
					v-model="queryParams.agentNo"
					clearable
					placeholder="请输入代理商/机构编号"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item label="账户类型" prop="type" label-width="120px">
				<el-select
					v-model="queryParams.accountType"
					placeholder="账户类型"
					clearable
					size="small"
					style="width: 240px"
				>
					<el-option label="全部" value="" />
					<el-option
						v-for="(item, key) in dicts.ACCOUNT_TYPE"
						:key="key"
						:label="item"
						:value="key"
					/>
				</el-select>
			</el-form-item>
			<el-form-item label="上游通道" prop="channelNo" label-width="120px">
				<el-select
					v-model="queryParams.channelNo"
					placeholder="请选择上游通道"
					style="width: 240px"
					clearable
				>
					<el-option
						v-for="item in acqChannelList"
						:label="item.name"
						:value="item.channelNo"
						:key="item.channelNo"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item
				label="一级名称"
				prop="oneAgentName"
				label-width="120px"
			>
				<el-input
					v-model="queryParams.oneAgentName"
					clearable
					placeholder="请输入一级名称"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item
				label="上级名称"
				prop="parentAgentName"
				label-width="120px"
			>
				<el-input
					v-model="queryParams.parentAgentName"
					clearable
					placeholder="请输入上级名称"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleQuery"
					v-permission="'AGENT_ACCOUNT_QUERY'"
				>
					查询
				</el-button>
				<el-button
					icon="el-icon-refresh"
					size="mini"
					@click="handleResetQuery"
					v-permission="'AGENT_ACCOUNT_QUERY'"
				>
					重置
				</el-button>
				<el-button
					icon="el-icon-download"
					size="mini"
					@click="handleExport"
					v-permission="'AGENT_ACCOUNT_EXPORT'"
				>
					导出
				</el-button>
				<el-button
					icon="el-icon-add"
					size="mini"
					@click="handleAdd"
					v-permission="'ACCOUNT_ADJUSTMENT_INSERT'"
				>
					调账
				</el-button>
			</el-col>
		</el-row>
		<CustomForm
			:dicts="dicts"
			:on-fresh="showCustomForm"
			@on-close="handlerCustomFormOnclose"
			:isAdd="true"
		>
		</CustomForm>
	</div>
</template>
<script>
import CustomForm from "./CustomForm";
import { FinancialToolsApi, TerminalApi } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: {}
		}
	},
	components: { CustomForm },
	data() {
		return {
			queryParams: {},
			createDateRange: [],
			updateDateRange: [],
			acqChannelList: [],
			showCustomForm: false
		};
	},
	async mounted() {
		//上游通道列表
		const result = await TerminalApi.acqChannel.listAllChannel();
		this.acqChannelList = (result && result.data) || [];
	},
	methods: {
		// 调账方法
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.commitChange();
		},
		// 查询方法
		handleQuery() {
			this.commitChange();
		},
		// 重置事件
		handleResetQuery() {
			this.queryParams = {};
			this.createDateRange = [];
			this.updateDateRange = [];
			this.commitChange();
		},
		handleAdd() {
			this.showCustomForm = true;
		},
		//导出功能
		handleExport() {
			this.$confirm("确定导出代理商账户", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(async () => {
				this.dealWithQueryParams();
				let result = await FinancialToolsApi.agentAccount.export(
					this.queryParams
				);
				if (result.success) {
					this.downloadFile(result.data);
				}
			});
		},
		dealWithQueryParams() {
			// 开始创建时间,截至创建时间
			let [startCreateTime, endCreateTime] = this.createDateRange;
			this.queryParams.startCreateTime = startCreateTime && startCreateTime + " 00:00:00";
			this.queryParams.endCreateTime = endCreateTime && endCreateTime + " 23:59:59";
			// 更新时间
			let [startUpdateTime, endUpdateTime] = this.updateDateRange;
			this.queryParams.startUpdateTime = startUpdateTime && startUpdateTime + " 00:00:00";
			this.queryParams.endUpdateTime = endUpdateTime && endUpdateTime + " 23:59:59";
		},
		// 拼接提交事件
		commitChange() {
			this.dealWithQueryParams();
			let params = { ...this.queryParams };
			this.$emit("on-change", params);
		}
	}
};
</script>

<style scoped></style>
